import IconJs from '../assets/svg/javascript.svg'
import IconReact from '../assets/svg/react.svg'
import IconGatsby from '../assets/svg/gatsby.svg'
import IconXd from '../assets/svg/adobexd.svg'
// import IconSass from '../assets/svg/sass.svg'

const socials = [
  {
    id: '001',
    name: 'JavaScript',
    address: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript',
    icon: IconJs,
  },
  {
    id: '002',
    name: 'React',
    address: 'https://reactjs.org/',
    icon: IconReact,
  },
  {
    id: '003',
    name: 'Gatsby',
    address: 'https://www.gatsbyjs.org/',
    icon: IconGatsby,
  },
  {
    id: '004',
    name: 'Adobe XD',
    address: 'https://www.adobe.com/pl/products/xd.html',
    icon: IconXd,
  },
  // {
  //   id: '005',
  //   name: 'Sass',
  //   address: 'https://sass-lang.com/',
  //   icon: IconSass,
  // },
]

export default socials
