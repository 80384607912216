import React from 'react'
import styled from 'styled-components'
// import AniLink from 'gatsby-plugin-transition-link/AniLink'

import Button from '../../components/Button'
// import { theme } from '../../utils/theme'

const Wrapper = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
`

const NavItem = styled.li`
  margin-right: 20px;

  :last-child {
    margin-right: 0;
  }
`

const NavButton = styled(Button)`
  font-size: 0.8em;

  ${({ theme }) => theme.media.desktop} {
    height: 40px;
  }
`

const UnstyledNavigation = ({ links, color }) => (
  <Wrapper>
    {links.map((link, index) => (
      <NavItem key={index}>
        {/* <AniLink cover direction="down" bg={theme.colors.primary} to={link.to}> */}
        <a href={link.to}>
          <NavButton color={color}>
            <p>{link.name}</p>
          </NavButton>
        </a>
        {/* </AniLink> */}
      </NavItem>
    ))}
  </Wrapper>
)

const Navigation = styled(UnstyledNavigation)`
  position: relative;
`

export default Navigation
