import React from 'react'
import SVG from 'react-inlinesvg'
import styled from 'styled-components'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import LogoSvg from '../../assets/svg/kobylarz-logo.svg'

const Wrapper = styled.div`
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  font-size: 18px;

  a {
    color: ${({ theme }) => theme.colors.white};
    text-decoration: none;
  }

  svg {
    width: auto;
    height: 100px;
  }

  ${({ theme }) => theme.media.desktop} {
    position: fixed;
    top: 50%;
    left: 0;
    width: 50%;
    transform: translate3d(0, -50%, 0);
  }
`

const Header = styled.h1`
  position: relative;
  margin: 0 10px 0 0;
  font-weight: ${({ theme }) => theme.font.bold};

  span {
    display: block;
    width: 0;
    height: 0;
    text-indent: -9999px;
    color: red;
  }
`

const Subheader = styled.p`
  background-image: linear-gradient(to right, #00d2ff, #3a7bd5);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
`

const Logo = ({ header, subheader }) => (
  <Wrapper>
    <AniLink cover direction="down" bg="#353230" to="/">
      <Header>
        <span>{header}</span>
        <SVG src={LogoSvg} />
      </Header>
    </AniLink>
    <Subheader>{subheader}</Subheader>
  </Wrapper>
)

export default Logo
