import React from 'react'
import SVG from 'react-inlinesvg'

import * as styles from './footer.module.css'

const Footer = ({ socials }) => (
  <footer className={styles.footer}>
    <ul className={styles.socials}>
      {socials.map(item => (
        <li>
          <a
            key={item.id}
            href={item.address}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SVG src={item.icon} />
          </a>
        </li>
      ))}
    </ul>
  </footer>
)

export default Footer
