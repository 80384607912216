import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.header`
  z-index: 3;
  position: fixed;
  top: 0;
  right: 30px;
  left: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 20px 0;

  h1 {
    background-image: linear-gradient(to right, #00d2ff, #3a7bd5);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
  }
`

const TopBar = ({ children }) => <Wrapper>{children}</Wrapper>

export default TopBar
