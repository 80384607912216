import styled from 'styled-components'

const Button = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  height: 35px;
  border: 2px solid #00d2ff;
  border-radius: 4px;
  padding: 0 20px;
  background: none;
  color: ${({ theme, color }) => (color ? color : theme.colors.primary)};
  font-family: 'josefin_sanssemibold', sans-serif;
  font-size: 14px;
  line-height: 0.9em;
  font-weight: ${({ theme }) => theme.font.bold};
  letter-spacing: 0.03em;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;

  p {
    background-image: linear-gradient(to right, #00d2ff, #3a7bd5);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
  }

  :hover {
    background: ${({ theme }) => theme.colors.transparent};
    color: ${({ theme }) => theme.colors.white};
  }

  ${({ theme }) => theme.media.desktop} {
  }
`

export default Button
