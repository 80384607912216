import IconGithub from '../assets/svg/github.svg'
import IconBehance from '../assets/svg/behance.svg'
import IconAwwwards from '../assets/svg/awwwards.svg'

const socials = [
  {
    id: '001',
    name: 'Github',
    address: 'https://github.com/pkobylarz',
    icon: IconGithub,
  },
  {
    id: '002',
    name: 'Behance',
    address: 'https://www.behance.net/kobylarz',
    icon: IconBehance,
  },
  {
    id: '003',
    name: 'Awwwards',
    address: 'https://www.awwwards.com/kobylarz',
    icon: IconAwwwards,
  },
]

export default socials
