import React from 'react'
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components'

import { theme } from '../utils/theme'
import './../utils/sanitize.css'
import './../utils/fonts.css'

import TopBar from '../components/TopBar'
import Footer from '../components/Footer'
import Logo from '../components/Logo'
import Navigation from '../components/Navigation'

import SVG from 'react-inlinesvg'
import IconHeart from '../assets/svg/heart.svg'

import socials from '../constants/socials'
import tools from '../constants/tools'

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  body {
    color: ${({ theme }) => theme.colors.white};
    font-family: 'josefin_sans', sans-serif;
    font-weight: ${({ theme }) => theme.font.light};
  }
`

const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background: #ffffff;
  transition: background 0.6s ease;

  :before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: linear-gradient(to bottom right, transparent, #000000);
    opacity: 0.05;
    content: '';
  }
`

const MainContainer = styled.main`
  z-index: 2;
  position: relative;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 0 30px;
  width: 100vw;
  height: 100vh;
  max-width: 2400px;
`

const Layout = ({ children, colorTheme }) => {
  const generalData = {
    address: 'kobylarz.co',
    logoHeader: 'Przemek Kobylarz',
    logoSubheader: 'Frontend/UI Developer',
  }
  const navigationData = [{ name: 'contact', to: 'mailto:przemek@kobylarz.co' }]

  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <Wrapper bgColor={colorTheme && colorTheme}>
          <TopBar>
            <h1>kobylarz.co</h1>
            <Navigation
              links={navigationData}
              color={colorTheme && colorTheme}
            />
          </TopBar>
          <MainContainer>{children}</MainContainer>
          <Footer
            socials={socials}
            tools={tools}
            address={generalData.address}
            text="Warsaw, Poland"
          />
        </Wrapper>
      </>
    </ThemeProvider>
  )
}

export default Layout
