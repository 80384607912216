export const colors = {
  transparent: 'rgba(0,0,0,0)',
  white: '#ffffff',
  primary: '#1488CC',
  dark: '#333745', //   gradient: 'linear-gradient(to bottom right,#FF512F,#DD2476)', //   gradient: 'linear-gradient(to bottom right,#414345,#232526)', //   gradient: 'linear-gradient(to bottom right,#7474BF,#348AC7)', //   gradient: 'linear-gradient(to bottom right,#C04848,#480048)',
  //   gradient: 'linear-gradient(to bottom right,#EDDE5D,#F09819)',
  //   gradient: 'linear-gradient(to bottom right,#6dd5ed,#2193b0)',
  //   gradient: 'linear-gradient(to bottom right,#fc6767,#ec008c)',
  gradient: 'linear-gradient(to bottom right,#1488CC,#2B32B2)',
}
//   gradient: 'linear-gradient(to bottom right,#8E2DE2,#4A00E0)',
