import { colors } from './colors'

export const theme = {
  colors,
  font: {
    light: 400,
    regular: 500,
    bold: 700,
  },
  media: {
    tablet: '@media (min-width: 728px)',
    desktop: '@media (min-width: 1224px)',
  },
}
